import {Local} from "@/utils/storage";

const STORAGE_KEY = "__PROGRESS__"
export const PROGRESS_KEY = {
  IMPORT_PRODUCT: {
    desc: "导入产品",
    key: "IMPORT_PRODUCT",
  },
  FAKER_IMPORT_PRODUCT:{
    desc: "导入产品",
    key: "FAKER_IMPORT_PRODUCT",
  }
}
const progress = {
  namespaced: true,
  state: Local.get(STORAGE_KEY) || {
    // key:values[]
    all: {},
    current: {},
  },
  getters: {
    current(state) {
      return state.current;
    },
  },
  mutations: {
    SET_ALL: (state, all) => {
      state.all = all
      Local.set(STORAGE_KEY, state)
    },
    SET_CURRENT(state, current) {
      state.current = current
      Local.set(STORAGE_KEY, state)
    },
  },
  actions: {
    /**
     * 设置当前进度条
     * @param commit
     * @param state
     * @param key
     * @param current
     * @returns {Promise<unknown>}
     * @constructor
     */
    SetCurrentProgress({commit, state}, {key, current}) {
      return new Promise((resolve, reject) => {
        if (key && typeof key === "string" && current && typeof current === "object") {
          const all = state.all;
          commit("SET_CURRENT", current)
          const element = all[key] = all[key] || [];
          const some = element.some((v, i) => {
            if (v.uuid === current.uuid) {
              element[i] = current;
            }
            return v.uuid === current.uuid
          });
          if (!some) element.push(current)
          commit("SET_ALL", all)
          resolve()
        } else {
          reject("Incorrect parameters");
        }
      })

    },
    GetLastProgress({commit, state}, key) {
      return new Promise((resolve, reject) => {
        if (key && typeof key === "string") {
          const {all,current} = state;
          if (current&&current.uuid?.startsWith(key)) {
            resolve(current)
          }else {
            const element = all[key];
            if (element&&element.length>0){
              resolve(element[element.length-1])
            }else {
              resolve(null)
            }
          }
        } else {
          reject("Incorrect parameters key:string");
        }
      })
    },
  }
}

export default progress
