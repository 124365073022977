import { listAllDictionary } from '@/api/dictionary'

const platformCode = ['operationSequence', 'unit', 'bigClassCode', 'classCode', 'storageMode']
const state = {
	list: [],
	map: {}
}
const mutations = {
	SET_LIST(state, list) {
		state.list = list
	},
	SET_SYSTEM_DICTIONARY(state, map) {
		state.map = map
	}
}
const actions = {
	GetDictionary({ state }) {
		return state.list
	},
	SetDictionary({ commit, dispatch }) {
		return new Promise((resolve, reject) => {
			listAllDictionary()
				.then((res) => {
					commit('SET_LIST', res.data)
					dispatch('SetSystemDictionary')
					resolve()
				})
				.catch(reject)
		})
	},
	SetSystemDictionary({ commit, state }) {
		const list = state.list
		const map = {}
		list.forEach((v, k) => {
			map[v.code] = v
		})
		commit('SET_SYSTEM_DICTIONARY', map)
	}
}
export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters: {
    dictionaryMap(state) {
			return state.map
		}
	}
}
