export default [
	{
		name: 'SubscriptionManagement',
		path: '/subscription',
		hidden: true,
		redirect: {
			name: 'SubscriptionManagementList'
		},
		component: 'Layout',
		alwaysShow: true,
		meta: {
			title: '订阅管理',
			icon: 'subscription',
			noCache: false,
			link: null
		},
		children: [
			{
				name: 'SubscriptionManagementList',
				path: 'list',
				hidden: false,
				component: 'SubscriptionManagement/list/index',
				meta: {
					title: '订阅记录',
					icon: 'time-range',
					noCache: false,
					link: null
				}
			}
		]
	},
	{
		name: 'ProductManagement',
		path: '/productManagement',
		hidden: false,
		redirect: {
			name: 'ProductManagement-Item'
		},
		component: 'Layout',
		alwaysShow: true,
		meta: {
			title: '品项管理',
			icon: 'ProductManagement',
			noCache: false,
			link: null
		},
		children: [
			{
				name: 'ProductManagement-Category',
				path: 'category',
				hidden: false,
				component: 'ProductManagement/category/index',
				meta: {
					title: '类别维护',
					icon: 'filter',
					noCache: false,
					link: null
				}
			},
			{
				name: 'ProductManagement-Item',
				path: 'item',
				hidden: false,
				component: 'ProductManagement/item/index',
				meta: {
					title: '品项维护',
					icon: 'layers',
					noCache: false,
					link: null
				}
			}
		]
	},
	{
		name: 'LabelManagement',
		path: '/labelManagement',
		hidden: false,
		redirect: {
			name: 'LabelManagement-List'
		},
		component: 'Layout',
		alwaysShow: true,
		meta: {
			title: '标签管理',
			icon: 'LabelManagement',
			noCache: false,
			link: null
		},
		children: [
			{
				name: 'LabelManagement-Alert',
				path: 'alert',
				hidden: false,
				component: 'LabelManagement/alert/index',
				meta: {
					title: '过期预警',
					icon: 'LabelManagement-Alert',
					noCache: false,
					link: null
				}
			},
			{
				name: 'LabelManagement-List',
				path: 'list',
				hidden: false,
				component: 'LabelManagement/list/index',
				meta: {
					title: '标签列表',
					icon: 'list',
					noCache: false,
					link: null
				}
			},
      {
        name: 'ExpirationManagement-Tag',
        path: 'tag',
        hidden: false,
        component: 'ExpirationManagement/tag/index',
        meta: {
          title: '标签制作',
          icon: 'ExpirationManagement-Tag',
          noCache: false,
          link: null
        }
      }
		]
	},
	{
		name: 'ExpirationManagement',
		path: '/expirationManagement',
		hidden: true,
		redirect: {
			name: 'ExpirationManagement-Setting'
		},
		component: 'Layout',
		alwaysShow: true,
		meta: {
			title: '过期管理',
			icon: 'ExpirationManagement',
			noCache: false,
			link: null
		},
		children: [
			{
				name: 'ExpirationManagement-Setting',
				path: 'setting',
				hidden: true,
				component: 'ExpirationManagement/setting/index',
				meta: {
					title: '过期设置',
					icon: 'ExpirationManagement-Setting',
					noCache: false,
					link: null
				}
			},
			{
				name: 'ExpirationManagement-Tag',
				path: 'tag',
				hidden: true,
				component: 'ExpirationManagement/label/index',
				meta: {
					title: '标签制作',
					icon: 'ExpirationManagement-Tag',
					noCache: false,
					link: null
				}
			}
		]
	},
	{
		name: 'EnterpriseManagement',
		path: '/enterpriseManagement',
		hidden: false,
		redirect: {
			name: 'EnterpriseManagement-Profile'
		},
		component: 'Layout',
		alwaysShow: true,
		meta: {
			title: '企业管理',
			icon: 'EnterpriseManager',
			noCache: false,
			link: null
		},
		children: [
			{
				name: 'EnterpriseManagement-Profile',
				path: 'profile',
				hidden: false,
				component: 'EnterpriseManagement/profile/index',
				meta: {
					title: '企业信息',
					icon: 'EnterpriseInfo',
					noCache: false,
					link: null
				}
			},
			{
				name: 'EnterpriseManagement-Store',
				path: 'store',
				hidden: false,
				component: 'EnterpriseManagement/store/index',
				meta: {
					title: '门店管理',
					icon: 'EnterpriseStore',
					noCache: false,
					link: null
				}
			},
			{
				name: 'EnterpriseManagement-Dept',
				path: 'dept',
				hidden: false,
				component: 'EnterpriseManagement/dept/index',
				meta: {
					title: '部门管理',
					icon: 'EnterpriseDept',
					noCache: false,
					link: null
				}
			}
		]
	},
	{
		name: 'UserManagement',
		path: '/userManagement',
		hidden: false,
		redirect: 'noRedirect',
		component: 'Layout',
		alwaysShow: true,
		meta: {
			title: '用户管理',
			icon: 'UserManagement',
			noCache: false,
			link: null
		},
		children: [
			{
				name: 'UserManagement-List',
				path: 'list',
				hidden: false,
				component: 'UserManagement/list/index',
				meta: {
					title: '用户列表',
					icon: 'UserManagement-List',
					noCache: false,
					link: null
				}
			},
			{
				name: 'UserManagement-Role',
				path: 'role',
				hidden: true,
				component: 'UserManagement/role/index',
				meta: {
					title: '角色维护',
					icon: 'UserManagement-Role',
					noCache: false,
					link: null
				}
			}
		]
	},
	{
		name: 'SystemManagement',
		path: '/systemManagement',
		hidden: false,
		redirect: 'noRedirect',
		component: 'Layout',
		alwaysShow: true,
		meta: {
			title: '系统管理',
			icon: 'system',
			noCache: false,
			link: null
		},
		children: [
			{
				name: 'DictionaryManagement',
				path: 'dictionaryManagement',
				hidden: true,
				component: 'SystemManagement/DictionaryManagement/index',
				meta: {
					title: '字典管理',
					icon: 'dict',
					noCache: false,
					link: null
				}
			},
			{
				name: 'SystemManagement-OperationSequence',
				path: 'operationSequence',
				hidden: false,
				component: 'SystemManagement/operationSequence',
				meta: {
					title: '操作步骤',
					icon: 'dict',
					noCache: false,
					link: null
				}
			},
			{
				name: 'SystemManagement-StorageMode',
				path: 'storageMode',
				hidden: false,
				component: 'SystemManagement/storageMode',
				meta: {
					title: '存储方式',
					icon: 'dict',
					noCache: false,
					link: null
				}
			},
			{
				name: 'SystemManagement-Unit',
				path: 'unit',
				hidden: false,
				component: 'SystemManagement/unit',
				meta: {
					title: '品项单位',
					icon: 'dict',
					noCache: false,
					link: null
				}
			},
			{
				name: 'SystemManagement-Task',
				path: 'task',
				hidden: false,
				component: 'SystemManagement/task',
				meta: {
					title: '任务列表',
					icon: 'download',
					noCache: false,
					link: null
				}
			},
			{
				name: 'SystemManagement-Setting',
				path: 'setting',
				hidden: false,
				component: 'SystemManagement/setting',
				meta: {
					title: '系统设置',
					icon: 'system',
					noCache: false,
					link: null
				}
			},
		]
	}
]
