/**
 * window.localStorage 浏览器永久缓存
 * @method set 设置永久缓存
 * @method get 获取永久缓存
 * @method remove 移除永久缓存
 * @method clear 移除全部永久缓存
 */
export const Local = {
  /**
   * 设置永久缓存
   * @param {string} key
   * @param {any} val
   */
  set(key, val) {
    window.localStorage.setItem(key, typeof val === 'string' ? val : JSON.stringify(val));
  },
  /**
   * 获取永久缓存
   * @param {string} key
   * @returns {null | any}
   */
  get(key) {
    const str = window.localStorage.getItem(key);
    if (str) {
      try {
        return JSON.parse(str);
      } catch (e) {
        console.error(e)
        return str
      }
    } else {
      return null;
    }
  },
  /**
   * 移除永久缓存
   * @param {string} key
   */
  remove(key) {
    window.localStorage.removeItem(key)
  },
  /**
   * 移除全部永久缓存
   */
  clear() {
    // window.localStorage.clear();
  },
};
