import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import user from './modules/user'
import tagsView from './modules/tagsView'
import permission from './modules/permission'
import settings from './modules/settings'
import getters from './getters'
import progress from './modules/progress'
import dictionary from './modules/dictionary'
import createPersistedState from 'vuex-persistedstate'
import cache from '@/store/modules/cache'

Vue.use(Vuex)
const store = new Vuex.Store({
	modules: {
		app,
		tagsView,
		permission,
		settings,
		progress,
		dictionary,
		cache,
		user
	},
	getters,
	plugins: [
		createPersistedState({
			key: 'DEXP',
			paths: ['dictionary', 'cache', 'user.info','user.token','user.name','user.avatar']
		})
	]
})

export default store
