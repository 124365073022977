import { login, logout, smsLogin } from '@/api/auth'
import { getToken, removeToken, setToken } from '@/utils/auth'
import Crypto from 'crypto'
import { getCurrentUserPermission } from '@/api/user/role'

const user = {
	namespaced: true,
	state: {
		token: getToken(),
		name: '',
		avatar: '',
		info: {},
		roles: [],
		permissions: []
	},

	mutations: {
		SET_TOKEN: (state, token) => {
			state.token = token
		},
		SET_EXPIRES_IN: (state, time) => {
			state.expires_in = time
		},
		SET_NAME: (state, name) => {
			state.name = name
		},
		SET_AVATAR: (state, avatar) => {
			state.avatar = avatar
		},
		SET_ROLES: (state, roles) => {
			state.roles = roles
		},
		SET_PERMISSIONS: (state, permissions) => {
			state.permissions = permissions
		},
		SET_INFO: (state, info) => {
			state.info = info
		}
	},

	actions: {
		/**
     * 用户名密码登录
     * @param commit
     * @param dispatch
     * @param userInfo
     * @returns {Promise<unknown>}
     */
		Login({ commit, dispatch }, userInfo) {
			const loginNo = userInfo.loginNo.trim()
			const password = userInfo.password
			return new Promise((resolve, reject) => {
				const md5 = Crypto.createHash('md5')
				md5.update(password)
				login(loginNo, md5.digest('hex'), userInfo.source)
					.then((res) => {
						const data = res.data
						setToken(data.token)
						commit('SET_INFO', data)
						commit('SET_TOKEN', data.token)
						dispatch('dictionary/SetDictionary','', { root: true })
						resolve()
					})
					.catch((error) => {
						reject(error)
					})
			})
		},
		/**
		 * 手机号登录
		 * @param commit
		 * @param userInfo
		 * @returns {Promise<unknown>}
		 */
		SmsLogin({ commit }, userInfo) {
			const mobile = userInfo.mobile.trim()
			const captcha = userInfo.captcha
			return new Promise((resolve, reject) => {
				smsLogin(mobile, captcha)
					.then((res) => {
						let data = res.data
						setToken(data.access_token)
						commit('SET_TOKEN', data.access_token)
						resolve()
					})
					.catch((error) => {
						reject(error)
					})
			})
		},
		/**
		 * 获取用户信息
		 * @param commit
		 * @param state
		 * @returns {Promise<unknown>}
		 */
		GetInfo({ commit, state }) {
			return new Promise((resolve, reject) => {
				const user = state.info
				getCurrentUserPermission(user.id)
					.then((res) => {
						commit(
							'SET_PERMISSIONS',
							res.data?.map((i) => {
								return i.code
							})
						)
						commit('SET_ROLES', ['ROLE_DEFAULT'])
						commit('SET_INFO', user)
						commit('SET_NAME', user.name)
						commit('SET_AVATAR', user.avatar || user.tenantLogo)
						resolve(state)
					})
					.catch((err) => {
						console.error(err)
						reject()
					})
			})
		},

		/**
		 * 退出系统
		 * @param commit
		 * @param state
		 * @returns {Promise<unknown>}
		 */
		LogOut({ commit, state }) {
			return new Promise((resolve, reject) => {
				logout()
					.then(() => {
						commit('SET_TOKEN', '')
						commit('SET_ROLES', [])
						commit('SET_PERMISSIONS', [])
						removeToken()
						resolve()
					})
					.catch((error) => {
						reject(error)
					})
			})
		},
		/**
		 * 前端 登出
		 * @param commit
		 * @returns {Promise<unknown>}
		 */
		FedLogOut({ commit }) {
			return new Promise((resolve) => {
				commit('SET_TOKEN', '')
				removeToken()
				resolve()
			})
		},
		SetTenantLogo({ commit, state }, tenantLogo) {
			commit('SET_INFO', {
				...state.info,
				tenantLogo
			})
		}
	}
}
export default user
